import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Checkbox, FormControlLabel, FormGroup, List, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { FieldDefinitionsDto } from "api-shared";
import { TFunction } from "i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDefaultCurrency } from "../../domain/currencies";
import { useGetDynamicFieldLabel } from "../../hooks/useGetDynamicFieldLabel";
import useSortableList from "../../hooks/useSortableList";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import SearchInput from "../input/SearchInput";
import TableColumnConfigColumnItem from "./TableColumnConfigColumnItem";

const ScrollableStack = styled(Stack)({
    flexGrow: 1,
    overflowY: "auto",
});

const DividedList = styled(List)(({ theme }) => ({
    padding: "0px",
    "& li:first-of-type": {
        borderTop: `0px`,
    },
    "& li:last-of-type": {
        borderBottom: `0px`,
    },
}));

export type OrderableColumnsRelation = { name: string; id: number };

interface ITableColumnConfigFormProps {
    columns: string[];
    disabledColumns: string[];
    updateColumns: (newColumns: string[]) => void;
    fieldDefinitions: FieldDefinitionsDto;
    translate: TFunction;
    translationPrefix?: string;
    disabled?: boolean;
}

const TableColumnConfigForm = ({
    columns,
    disabledColumns,
    updateColumns,
    fieldDefinitions,
    translate,
    translationPrefix,
    disabled = false,
}: ITableColumnConfigFormProps) => {
    const indexedColumns = columns.map((name, index) => ({ name, id: index + 1 }) as OrderableColumnsRelation);

    const [filterKey, setFilterKey] = useState("");

    const containerRef = useRef<HTMLDivElement>(null);
    const clientCurrency = useDefaultCurrency();

    const { getDynamicColumnLabel } = useGetDynamicFieldLabel();

    const selectColumn = (name: string) => updateColumns([...indexedColumns.map((c) => c.name), name]);
    const deselectColumn = (name: string) => updateColumns(indexedColumns.filter((c) => c.name !== name).map((c) => c.name));

    const handleColumnToggle = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;
        if (checked) {
            selectColumn(name);
        } else {
            deselectColumn(name);
        }
    };

    const defaultColumns = Object.values(fieldDefinitions)
        .filter((col) => col.attributeName === undefined)
        .map((col) => col.name);
    const lowerCaseFilterKey = filterKey.toLowerCase();
    const filteredColumns = Object.keys(fieldDefinitions)
        .filter(
            (name) =>
                getDynamicColumnLabel(
                    translationPrefix !== undefined && defaultColumns.includes(name) ? `${translationPrefix}.${name}` : name,
                    clientCurrency.isoCode,
                )
                    .toLowerCase()
                    .indexOf(lowerCaseFilterKey) > -1,
        )
        .map((name) => fieldDefinitions[name]);

    const theme = useTheme();
    const isDenseLayout = useMediaQuery(theme.breakpoints.down("md"));

    const DragAndDropModifiers = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];

    const [reorderedRelations, setReorderedRelations] = useState<OrderableColumnsRelation[]>();

    const setNewItems = (newItems: OrderableColumnsRelation[]) => {
        setReorderedRelations(newItems);

        if (newItems === undefined) {
            return;
        }
        updateColumns(newItems?.map((c) => c.name));
    };

    const { sensors, onDragEnd, onDragStart } = useSortableList({ items: indexedColumns, updateItems: setNewItems });

    useEffect(() => {}, [reorderedRelations, updateColumns]);

    return (
        <Stack spacing={3} direction={isDenseLayout ? "column" : "row"} sx={{ height: isDenseLayout ? null : theme.spacing(60) }}>
            <Stack spacing={2} flexBasis={0} flexGrow={1}>
                <Typography variant="subtitle1">{translate("available_columns")}</Typography>
                <SearchInput searchKey={filterKey} onChange={setFilterKey} translate={translate} />
                <ScrollableStack>
                    <FormGroup>
                        {filteredColumns.map(({ name, attributeName }) => (
                            <Tooltip
                                key={name}
                                title={
                                    disabledColumns.includes(name) && name === "title"
                                        ? translate(translationKeys.VDLANG_GRID_CONFIG_TITLE_HINT)
                                        : ""
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            name={name} // used by event handler to know the targeted column
                                            checked={indexedColumns.findIndex((c) => c.name === name) >= 0}
                                            onChange={handleColumnToggle}
                                            disabled={disabled || disabledColumns.includes(name)}
                                        />
                                    }
                                    label={getDynamicColumnLabel(
                                        translationPrefix !== undefined && attributeName === undefined
                                            ? `${translationPrefix}.${name}`
                                            : name,
                                        clientCurrency.isoCode,
                                    )}
                                />
                            </Tooltip>
                        ))}
                    </FormGroup>
                </ScrollableStack>
            </Stack>
            <Stack spacing={2} flexBasis={0} flexGrow={1}>
                <Typography variant="subtitle1">
                    {translate("selected_columns")} ({indexedColumns.length})
                </Typography>
                <ScrollableStack>
                    <Paper ref={containerRef}>
                        {containerRef != null ? (
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                                modifiers={DragAndDropModifiers}
                            >
                                <SortableContext items={indexedColumns} strategy={verticalListSortingStrategy} disabled={disabled}>
                                    <DividedList>
                                        {indexedColumns.map((item, index) => (
                                            <TableColumnConfigColumnItem
                                                key={item.id}
                                                item={item}
                                                isDefault={defaultColumns.includes(item.name)}
                                                isColumnDisabled={disabledColumns.includes(item.name)}
                                                disabled={disabled}
                                                translate={translate}
                                                translationPrefix={translationPrefix}
                                                onRemove={deselectColumn}
                                            />
                                        ))}
                                    </DividedList>
                                </SortableContext>
                            </DndContext>
                        ) : null}
                    </Paper>
                </ScrollableStack>
            </Stack>
        </Stack>
    );
};

export default TableColumnConfigForm;
