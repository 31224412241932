import { Parser } from "@json2csv/plainjs";
import { stringExcel } from "@json2csv/formatters";
import { merge } from "lodash";

const defaultOptions = {
    withBOM: true,
    delimiter: ";",
};

export function parse(data: Record<string, unknown> | Array<Record<string, unknown>>, options?: object, formatAsExcel = false) {
    const opts = merge(
        {},
        defaultOptions,
        formatAsExcel && {
            eol: "\r\n",
            excelStrings: true,
            formatters: {
                string: stringExcel,
            },
        },
        options,
    );
    const CsvParser = new Parser(opts);
    return CsvParser.parse(data);
}
